import galleryList from "../gallery-items/gallery-items.json";

export async function getGalleryItems() {
  const galleryItemsData = await Promise.all(
    galleryList.map(async (item) => {
      const mediaSrc = await import(`../gallery-items/${item.filename}`);
      return {
        ...item,
        src: mediaSrc.default
      };
    })
  );
  return galleryItemsData;
}
