import React from "react";
import { Link } from "react-router-dom";
import "../../styles/global/App.css";

const HomePage = () => {
  const startYear = 2002;
  const currentYear = new Date().getFullYear();
  const yearsOfExperience = currentYear - startYear;

  // Obfuscated email
  const email = "ten.samohtcire@cire".split("").reverse().join("");

  return (
    <div className="homepage">
      <h1>Hello, I'm Eric</h1>
      <h2>Senior Software Engineer working with Enterprise Generative AI</h2>

      <div className="about-me">
        <p>
          As part of the Emerging Tech Team for a US Class I Railway, my day job
          is to experiment, enable, evangelize, educate, and overall accelerate
          the safe adoption of GenAI.
        </p>
        <p>
          I facilitate weekly internal GenAI Task Force meetups to
          cross-pollinate ideas and GenAI Developer meetups to internally
          open-source findings.
        </p>
        <p>
          Whether an individual contributor, a member of small teams, or as part
          of the larger community, I'm passionate about {" "}
          <span className="highlighted">architecting</span>, {" "}
          <span className="highlighted">developing</span>, and{" "}
          <span className="highlighted">deploying</span>{" GenAI "}
          <span className="highlighted">apps</span> and{" "}
          <span className="highlighted">automations</span> to drive
          transformation.
        </p>
      </div>

      <h3>Let's put GenAI to work!</h3>
      <br></br>

      <h2>Technical Skills and Interests</h2>
      <div className="skills-grid">
        <div>GenAI Training Development and Delivery</div>
        <div>Creative Prompt Engineering</div>
        <div>Real-Time Voice and Video APIs</div>
        <div>Unstructured Data (DocIntel) Pipelines</div>
        <div>Novel Diagrammming Tools</div>
        <div>Using GenAI to Create Knowledge Graphs</div>
        <div>RAG Experiments</div>
        <div>Serverless Automations, Workflows and Agents</div>
        <div>ReactJS, Streamlit, Dash, Plotly and Leaflet Web UIs</div>
        <div>Azure Python SDKs</div>
        <div>Entra ID auth</div>
        <div>Novel Requirements Elicitation Methods</div>
        <div>Azure Resource Deployment and Admin</div>
        <div>Static Site Generators and Markdown</div>
      </div>

      <h2>Enterprise Experience {yearsOfExperience}+ yrs</h2>
      <ul className="experience-list">
        <li>Emerging Tech.: Software Engineer I / Senior</li>
        <li>EngTech.: Project Manager I / II</li>
        <li>Training: Railway Technical Trainer</li>
        <li>Maintenance: Railway Signal Maintainer</li>
        <li>Construction: Railway Signalman</li>
        <li>Apprentice: Railway Signalman's Apprentice</li>
      </ul>

      <h2>Personal Interests</h2>
      <div className="skills-grid">
        <div>Quantified Self</div>
      </div>

      <h2>Contact</h2>
      <div className="contact-section">
        <p>
          Feel like chatting about GenAI or other interesting things? Ping me!
        </p>
        <div className="contact-grid">
          <a href={`mailto:${email}`}>Email</a>
          <Link to="https://mvp.microsoft.com/en-us/PublicProfile/5003791?fullName=Eric%20Thomas">
            MVP Profile
          </Link>
          <Link to="https://github.com/seadude">GitHub</Link>
          <Link to="https://www.linkedin.com/in/seadude/">LinkedIn</Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
