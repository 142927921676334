import React, { useState } from 'react';
import Modal from 'react-modal';
import '../styles/component/ModalComponent.css';

const ModalComponent = ({ isOpen, onRequestClose, selectedItem }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      }).catch((err) => {
        console.error('Failed to copy: ', err);
      });
    } else {
      fallbackCopyTextToClipboard(text);
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    try {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.position = "fixed";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal"
      overlayClassName="modal-overlay"
    >
      {selectedItem && (
        <div className="modal-content">
          <div className="modal-photo-container">
            {selectedItem.type === 'image' ? (
              <img src={selectedItem.src} alt="Selected Item" className="modal-photo" />
            ) : (
              <video controls className="modal-photo">
                <source src={selectedItem.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
          <p className="modal-text">{selectedItem.fullText}</p>
          <button className={`copy-tab ${copied ? 'copied' : ''}`} onClick={() => copyToClipboard(selectedItem.fullText)}>
            {copied ? 'Prompt copied' : 'Copy'}
          </button>
        </div>
      )}
    </Modal>
  );
};

export default ModalComponent;
