import React, { useState, useEffect } from 'react';
import Navigation from '../layout/Navigation';
import Footer from '../layout/Footer';
import { getGalleryItems } from '../../services/gallery-items';
import '../../styles/component/pages/GalleryPage.css';
import ModalComponent from '../../components/ModalComponent';

function GalleryPage() {
  const [galleryItems, setGalleryItems] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchGalleryItems = async () => {
      const items = await getGalleryItems();
      setGalleryItems(items);
    };
    fetchGalleryItems();
  }, []);

  const openModal = (item) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <div>
      <Navigation />
      <div className="gallery-container">
        {galleryItems.map((item, index) => (
          <div key={index} className="gallery-item" onClick={() => openModal(item)}>
            {item.type === 'image' ? (
              <img src={item.src} alt="Gallery Item" />
            ) : (
              <video controls>
                <source src={item.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            <p className="gallery-item-text">
              <em>{item.blurb}</em>
              {item.fullText.length > item.blurb.length && (
                <span className="ellipsis" onClick={() => openModal(item)}>...</span>
              )}
            </p>
          </div>
        ))}
      </div>
      <ModalComponent isOpen={modalOpen} onRequestClose={closeModal} selectedItem={selectedItem} />
      <Footer />
    </div>
  );
}


export default GalleryPage;
