import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleLinkClick = () => {
    setIsOpen(false); // Close the menu when a link is clicked
  };

  return (
    <nav>
      <Link to="/" onClick={handleLinkClick}>
        <img src="/logo.png" alt="ericthomas.net logo" className="logo" />
      </Link>
      <button className="hamburger" onClick={() => setIsOpen(!isOpen)}>
        &#9776;
      </button>
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li><Link to="/" onClick={handleLinkClick}>Home</Link></li>
        <li><Link to="/gallery" onClick={handleLinkClick}>GenAI Gallery</Link></li> {/* Add onClick */}
        <li><Link to="/blog" onClick={handleLinkClick}>Blog</Link></li>
        <li><Link to="/public-map" onClick={handleLinkClick}>Map</Link></li>
      </ul>
    </nav>
  );
};

export default Navigation;
